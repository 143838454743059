.login-modal {
    border-radius: 30px;
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: #e4e4e4;
}

.login-modal-container {
    max-height: 400px;
    overflow-y: auto;
    padding: 33px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    scrollbar-width: thin;
    scrollbar-color: #444 #1e1e1e;
}

.login-modal input {
    margin-bottom: 1em;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    background-color: #2c2c2c;
    color: #e4e4e4;
    border: none;
    outline: none;
}

.login-modal button.highlight {
    background-color: #218b01;
    color: white;
    border: none;
    padding: 0.75em 1.5em;
    cursor: pointer;
    border-radius: 10px;
}

.login-modal button.highlight:hover {
    background-color: #27a800 !important;
  }
  