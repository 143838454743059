.topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 20px;
  padding: 20px;
}

.topic-card {
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  text-align: center;
  padding: 20px;
  transition: transform 0.2s ease-in-out;
  height: 120px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 20px 40px -10px;
  filter: saturate(0);
}

.topic-card:hover {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  filter: saturate(1);
}

.emoji {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.name {
  font-size: 0.8em;
  font-weight: bold;
  color: hsl(0, 0%, 51%);
}

.show-more-button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.show-more-button:hover {
  background-color: #0056b3;
}

.topics-grid .topic-button-wrapper {
  text-align: center;
}

.topics-grid .topic-button-wrapper:hover .remove-button {
  opacity: 1;
}

.topics-grid .remove-button {
  margin-top: 0.5em;
  margin-left: 0.5em;
  color: rgb(196, 196, 196);
  border: none;
  cursor: pointer;
  opacity: 0;
}

.topics-grid .remove-button:hover {
  color: white;
  /* background: rgba(255, 255, 255, 0.35); */
}