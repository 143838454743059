.hand-card {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 10px;
  background: #28292b;
  color: rgb(187, 187, 187);
  font-size: 14px;
  transition: transform 0.3s, border 0.3s;
  padding: 1em;
  border: 1px solid black;
  height: 300px;
  max-width: 250px;
}

.hand-card small {
  font-size: 12px;
}

.hand-card.correct {
  animation: moveUp 2s ease forwards, disappear 1s ease-in-out
}

.hand-card.wrong {
  border: 1px solid rgb(207, 5, 5);
  animation: shake 0.5s ease forwards
}

.hand-card.used {
  border: 1px solid rgb(255, 255, 255);
  animation: moveUp 2s ease forwards;
}


.hand-card-multiplier {
  display: none;
  z-index: 1;
}

.hand-card-multiplier .number {
  color: #11ad11;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  border-radius: 100%;
  transform: rotate(10deg);
}

@media (max-width: 742px) {
  .hand-card {
    margin-bottom: 10px;
    height: 245px;
    font-size: 12px;
    max-width: 200px;
  }

  .hand-card h1 {
    font-size: 18px;
  }

  .hand-card-multiplier .number {
    font-size: 18px;
    transform: rotate(10deg);
  }
}

.hand-card .card-point-wrapper {
  color: #11ad11;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  width: fit-content;
}

.hand-card .card-point-value {
  line-height: 1;
}

.hand-card .card-point-tag {
  text-align: center;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

/* Fade out for all states */
@keyframes disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
    /* Ensure the card is hidden after the animation */
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  12.5% {
    transform: translateX(-5px);
  }

  25% {
    transform: translateX(5px);
  }

  37.5% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  62.5% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  87.5% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}


@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  display: inline-block;
  animation: bounce 2s infinite;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.pulse {
  display: inline-block;
  animation: pulse 1.5s infinite;
}

.transparent {
  opacity: 0;
}