.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in;
}

.modal-backdrop.soft {
  background-color: rgba(0, 0, 0, 0.1);
}

.modal-backdrop.hard {
  background-color: rgba(0, 0, 0, 0.55);
}

.modal-content {
  color: rgb(182, 182, 182);
  border-radius: 8px;
  width: 600px;
  max-width: 90%;
  cursor: default;
  overflow-y: auto;
  max-height: 95vh;
}

.modal-content {
  margin-bottom: 0.75em;
}

.close-button {
  margin-top: 0.5em;
  margin-left: 0.5em;
  padding: 5px 20px;
  border-radius: 14px;
  background: #3f3f3fe8;
  color: white;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.35);
}

.fade-in {
  animation: fade-in 0.25s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

h1 {
  font-size: 2em;
}