#SearchPage {
  min-height: 100%;
}

#SearchPage .logo {
  width: calc(70px + 1.5vw);
  height: calc(70px + 1.5vw);
  background: #303030bd;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border-radius: 100%;
  margin-right: 1em;
}

#SearchPage .warning{
  padding: 0.5em;
  color: rgb(146, 146, 146) !important;
}

#SearchPage .text-light {
  color: rgb(201, 201, 201) !important;
}

#SearchPage .title-wrapper {
  border-radius: 3em;
  padding: 0em 0em;
  margin-bottom: 2em;
}

#SearchPage input {
  display: inline-block;
  color: rgb(182, 182, 182);
  font-size: 16px;
  border-radius: 30px;
  padding: 0.75em 1.25em;
  border: none;
  margin-top: 0.5em;
  margin-left: 0.5em;
  background: #3b3b3bbd;
  cursor: pointer;
}

/* CSS file or in a style block */
#SearchPage input::placeholder {
  color: #aaa;
  /* Light grey color for the placeholder */
  opacity: 1;
  /* Ensure full opacity */
}

#SearchPage input:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaa;
}

#SearchPage input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aaa;
}

.cursor-text {
  cursor: text !important;
}