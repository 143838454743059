@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  cursor: default;
}

:root {
  color-scheme: dark;
  --contexify-menu-bgColor: #000000;
  --contexify-separator-color: rgba(0, 0, 0, .2);
  --contexify-item-color: #adadad;
  --contexify-activeItem-color: #ffffff;
  --contexify-activeItem-bgColor: #282c2f;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

main {
  height: 100%;
}

.sliding-panel-container.active {
  background-color: transparent !important;
}

.main-bg,
body {
  background-color: rgb(25, 25, 25);
}

.App button,
.App a {
  margin: 0.5em;
}

.App a:hover {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.inner-shadow {
  box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, rgba(0, 0, 0, .3) 0px 30px 100px -24px;
}

.smart-notifications .glass {
  display: none;
}

.smart-notifications .smart-notification-panel {
  max-width: 28rem;
}

@media (orientation: portrait) {
  .smart-notifications .smart-notification-panel {

    max-width: 95%;
  }
}

.sliding-panel-container .panel-content {
  text-align: center;
}

.water-mark {
  padding: 1em;
  text-align: end;
  position: fixed;
  bottom: 0;
  right: 0;
}

.swiper-slide {
  align-self: center;
}

.smart-notification-panel {
  border-radius: 12px;
  background-color: rgb(63, 63, 70);
}

.vis-network:focus {
  border: none !important;
  outline: none !important;
}

@media (orientation: portrait) {
  .glass {
    height: 70vh !important;
  }
}

.navbar {
  position: absolute;
  z-index: 2;
  padding: 0;
  display: flex;
  justify-content: start;
  padding-top: 0.5em;
}

.navbar-item {
  display: inline-block;
  color: rgb(182, 182, 182);
  font-size: 16px;
  border-radius: 30px;
  padding: 0.5em;
  border: 1px solid rgb(85, 85, 85);
  margin-left: 0.5em;
  background: #1e1e1e;
  cursor: pointer;
  line-height: 0.9;
}

.navbar-item:disabled {
  color: rgb(119, 119, 119);
  background: #1e1e1e;
  border: none;
  cursor: unset;
}

.navbar-item:not(.disabled):hover {
  background: #626262bd;
}


.navbar-item.clean {
  border: none;
  background: none;
  padding: 0;
}

.icon {
  min-width: unset;
}

.icon svg {
  font-size: 12px;
}

.icon-sm {
  min-width: unset;
  font-size: 12px;
  max-height: 27px;
  width: 27px;
}

.szh-menu-button {
  margin: 0 !important;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: white;
}

.title {
  font-size: 18px;
}

.bold {
  font-weight: bold
}

.logo-title {
  padding-bottom: 0.1em;
}