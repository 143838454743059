body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.disabled {
  cursor: default !important;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* .blink_me {
  animation: blinker 1s linear infinite;
} */

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.rotating svg{
  animation: blinker 1s linear infinite,  rotating 1s linear infinite;
}