/* Chat Container */
.chat-container {
  /* border: 1px solid rgb(61, 61, 61); */
  border-radius: 30px;
  background-color: #1e1e1e;
  /* Dark background */
  display: flex;
  flex-direction: column;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); */
  overflow: hidden;
  color: #e4e4e4;
  /* Light font color */
}

/* .chat-container.incorrect{
  border: red 1px solid;
} */

.chat-container.correct{
  border: rgb(34, 156, 29) 1px solid;
}

/* Messages Section */
.messages-container {
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.1) 0px 18px 36px -18px inset; */
  max-height: 400px;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #444 #1e1e1e;
  /* Firefox */
}

/* For Webkit Browsers (Chrome, Safari) */
.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-track {
  background-color: #1e1e1e;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 10px;
}

/* Animation for Messages */
@keyframes messageFadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Individual Message */
.message {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
  flex-direction: column;
  max-width: 80%;
  padding: 12px;
  border-radius: 10px;
  line-height: 1.5;
  animation: messageFadeIn 0.25s ease-in-out;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
}

/* User Message */
.message-user {
  align-self: flex-end;
  background-color: #3a3a3a;
  /* Light dark for user messages */
  color: #bbbbbb;
}

/* Bot Message */
.message-bot {
  align-self: flex-start;
  background-color: #2c2c2c;
  /* Darker for bot messages */
  color: #bebebe;
}

/* Typing Indicator */
.loading {
  font-size: 14px;
  color: #bbb;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  animation: typingDots 1.5s infinite steps(5, end);
}

/* Typing Dots Animation */
@keyframes typingDots {
  0% {
    content: '.';
  }

  20% {
    content: '..';
  }

  40% {
    content: '...';
  }

  60% {
    content: '..';
  }

  80% {
    content: '.';
  }

  100% {
    content: '.';
  }
}

.input-container {
  padding: 16px;
}

.chat-parent input {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  background-color: #2c2c2c;
  color: #e4e4e4;
  border: none;
  outline: none;
}

.chat-parent input::placeholder {
  color: #888;
}

.chat-parent button {
  background-color: #218b01;
  color: white;
  border: none;
  padding:0.75em 1.5em;
  cursor: pointer;
}

.chat-parent button:hover {
  background-color: #27a800 !important;
}

.chat-parent .timestamp {
  font-size: 12px;
  color: #888;
}

.chat-parent code {
  font-size: 16px;
  color: #27a800;
}

.chat-parent pre code{
  font-size: 14px;
  color: #b4b4b4;
}

.chat-parent pre {
  font-size: 14px;
  background: #1e1e1e;
  padding: 1em;
  border-radius: 1em;
  margin: 1em 0;
}

.chat-parent p {
  margin-bottom: 0.75em;
}