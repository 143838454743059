.game-points-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.5s ease;
}

/* .points-small {
  font-size: 12px;
} */

.points-big {
  font-size: 1.25em;
  color: #13a700;
  font-weight:900;
  text-align: center;
  text-shadow:
      2px 2px 0 #000,
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;
}

.level-up-big {
  font-size: 2.25em;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-top: -100px;
  text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;
}
.z-1 {
  z-index: 1;
}

.absolute {
  position: absolute;
}

.center-x {
  left: 50%;
  transform: translateX(-50%);
}

.center-y {
  top: 50%;
}

.popIn {
  animation: popIn 0.5s ease forwards;
}

.popOut {
  animation: popOut 0.5s ease forwards;
}

@keyframes popIn {
  0% {
    transform: scale(1) translateY(0);
    ;
    opacity: 0;
  }

  100% {
    transform: scale(1.5)translateY(-50%);
    opacity: 1;
  }
}

@keyframes popOut {
  0% {
    transform: scale(1.5) translateY(-50%);
    opacity: 1;
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 0;
    display: none;
  }
}